import React from 'react';
import {ContactSection} from "@renaissancerentals/renaissance-component";
import {Icon, Modal} from "@contentmunch/muncher-ui";
import "./assets/ContactModal.scss";
import {renaissance} from "../../data/RenaissanceData";

export const ContactModal: React.FC<ContactModalProps> = ({showContactModal, contactModalCloseHandler}) => {

    return (
        <div className="div-contact--modal">
            <Modal show={showContactModal} setShow={contactModalCloseHandler}>
                <div className="close" onClick={contactModalCloseHandler}>
                    <Icon name="close" size="medium"/>
                </div>
                <ContactSection subject="Message from renaissancerentals.com" variant="long"
                                propertyId="renaissance-rentals"
                                contactNumber={renaissance.contact.number}/>
            </Modal>
        </div>
    )
};

export interface ContactModalProps {
    showContactModal: boolean;
    contactModalCloseHandler: () => void;
}
