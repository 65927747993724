import React from "react";
import {renaissance} from "../../data/RenaissanceData";
import {Link} from "react-router-dom";

export const FooterNav: React.FC = () => {
    return (
        <ul>
            <li><Link to="/apply">apply</Link></li>
            <li><a href="/contact">contact</a></li>
            <li><a href="/floorplans">search</a></li>
            <li><a href="https://app.mezo.io/r/renaissance">maintenance</a></li>
            <li><a href={"tel:" + renaissance.contact.number}>{renaissance.contact.display}</a></li>
        </ul>
    );
}
