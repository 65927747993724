import React from 'react';
import './assets/TeamHero.scss';
import heroImage from "./assets/Team-hero.jpg";
import mobileHeroImage from "./assets/Team-hero-mobile.jpg";

export const TeamHero: React.FC = () => {

    return (
        <section className="section-hero-team">
            <div className="mobile">
                <img src={mobileHeroImage} className="hero-img" alt="hero"/>
            </div>
            <div className="main">
                <img src={heroImage} className="hero-img" alt="hero"/>
            </div>
        </section>

    );
}