import React from 'react';
import './assets/BlogCard.scss';
import defaultAvatar from "@renaissancerentals/renaissance-component/lib/assets/default-avatar.png";
import {Link} from "react-router-dom";
import {BlogProps} from "./data/Blog";

export const BlogCard: React.FC<BlogProps> = ({blog}) => {

    return (
        <div className="blog-card">
            <Link to={"/blogs/" + blog.id}>
                <img src={blog.img == null ? defaultAvatar : blog.img} alt={blog.title}/>
            </Link>
            <p className="blog-card--title">
                <Link to={"/blogs/" + blog.id}>{blog.title}</Link>
            </p>
        </div>
    );
}