import React from "react";
import magnifyIcon from "./assets/MagnifyingGlass.png";
import calendarIcon from "./assets/Calendar.png";
import {Link} from "react-router-dom";
import applyIcon from "./assets/Apply.png";
import {FloorplanCardSkeleton, FloorplanCardSlider, PropertyDetails} from "@renaissancerentals/renaissance-component";
import "./assets/PropertyAction.scss";

export const PropertyAction: React.FC<PropertyActionProps> = (
    {
        handleRefToContact, handleRefToFloorPlan, property, isLoading
    }) => {
    return (
        <section className="section-action">
            <div className="container">
                <div className="actions">
                    <div className="action">
                        <div className="action--icon">
                            <a href="#floorPlan" onClick={handleRefToFloorPlan}>
                                <img src={magnifyIcon} alt="search icon" height={40}/>
                            </a>
                        </div>
                        <div className="action-content">
                            <h2><a href="#floorPlan" onClick={handleRefToFloorPlan}>Find
                                your {property.name} Apartment</a></h2>
                            <p>{property.description}</p>
                        </div>
                    </div>
                    <div className="action">
                        <div className="action--icon">
                            <a href="#contact" onClick={handleRefToContact}>
                                <img src={calendarIcon} alt="schedule icon" height={40}/>
                            </a>
                        </div>
                        <div className="action-content">
                            <h2><a href="#contact" onClick={handleRefToContact}>Schedule a Tour</a>
                            </h2>
                            <p>
                                Ask a question or schedule a tour, we’re here for you all week,
                                every week.
                            </p>
                        </div>
                    </div>
                    <div className="action">
                        <div className="action--icon">
                            <Link to="/apply"> <img src={applyIcon}
                                                    alt="apply icon"
                                                    height={40}/></Link>
                        </div>
                        <div className="action-content">
                            <h2><Link to="/apply">Apply Online &raquo;</Link></h2>
                            <p>
                                It’s almost yours; just fill out the Free App and we’ll be in touch
                                soon!
                            </p>
                        </div>
                    </div>

                </div>
                <div className="main">
                    {isLoading ? <FloorplanCardSkeleton/> :
                        <>{property?.floorplans ? <FloorplanCardSlider size="small"
                                                                       propertyId={property.id}
                                                                       floorplans={property.floorplans
                                                                           .filter(floorplan => floorplan.active)
                                                                           .filter(floorplan => floorplan.featured)}/> : ""}

                        </>

                    }

                </div>

            </div>
        </section>
    );
}

export interface PropertyActionProps {

    handleRefToFloorPlan: () => void;
    handleRefToContact: () => void;
    property: PropertyDetails;
    isLoading: boolean;
}