import React, {useEffect, useState} from 'react';
import './assets/BlogHighlightSection.scss';
import {Blog} from "../data/Blog";
import {BlogHighlightCard} from "../BlogHighlightCard";
import {getRecentBlogs} from "../../../services/BlogService";

export const BlogHighlightSection: React.FC<BlogHighlightSectionProps> = ({sectionTitle}) => {

    const [blogs, setBlogs] = useState<Blog[]>([]);
    useEffect(() => {
        getRecentBlogs(3).then((data) => {
            setBlogs(data.blogs);
        });
    }, []);
    return (
        <section className="section-blog-highlight">
            <div className="container">
                <h1 className="heading">{sectionTitle ? sectionTitle : "Up on the blog..."}</h1>
                <div className="div-highlight--blogs">
                    {blogs.map((blog, index) => (
                        <BlogHighlightCard key={index} blog={blog}/>
                    ))}
                </div>
            </div>
        </section>

    );
}

export interface BlogHighlightSectionProps {
    sectionTitle?: string;
}
