import React, {useState} from 'react';
import './assets/MainHeader.scss';
import {Logo} from "../../logo/Logo";
import {Nav} from "../nav/Nav";
import {Button, Icon, Modal} from "@contentmunch/muncher-ui";

export const MainHeader: React.FC<MainHeaderProps> = ({contactClickHandler, leasingOfficesClickHandler}) => {

    const [showModal, setShowModal] = useState(false);
    const modalCloseHandler = () => {
        setShowModal(false);
    }
    const hamburgerClickHandler = () => {
        setShowModal(true);
    }

    return (
        <header className="header-main">
            <div className="div-mobile">
                <div className="header">
                    <Modal show={showModal} setShow={modalCloseHandler}>
                        <Nav
                            modalClose={modalCloseHandler}
                            contactClickHandler={contactClickHandler}
                            leasingOfficesClickHandler={leasingOfficesClickHandler}
                        />
                    </Modal>
                    <div className="left">
                        <Button onClick={hamburgerClickHandler} variant="secondary">
                            <Icon name="hamburger" size="medium"/>
                        </Button>
                    </div>
                    <Logo/>
                    <div className="right">
                        <Button onClick={contactClickHandler} variant="secondary" title="Contact us">
                            <Icon name="mail" size="small"/>
                        </Button>
                    </div>
                </div>
            </div>
            <div className="div-main">
                <div className="header">
                    <div className="left"/>
                    <Nav
                        modalClose={modalCloseHandler}
                        contactClickHandler={contactClickHandler}
                        leasingOfficesClickHandler={leasingOfficesClickHandler}
                    >
                        <Logo/>
                    </Nav>
                    <div className="right"/>
                </div>
            </div>
        </header>
    )
};

export interface MainHeaderProps {
    contactClickHandler: () => void;
    leasingOfficesClickHandler: () => void;
}