import React, {useState} from "react";
import {Button, Checkbox} from "@contentmunch/muncher-ui";
import "./assets/Maintenance.scss";
import {Helmet} from "react-helmet-async";

export const MaintenanceSection: React.FC = () => {

    const [isAirconChecked, setIsAirconChecked] = useState(false);
    const [isDishwasherChecked, setIsDishwasherChecked] = useState(false);
    const [isElectricalChecked, setIsElectricalChecked] = useState(false);
    const [isGarbageDisposalChecked, setIsGarbageDisposalChecked] = useState(false);
    const [isLightBulbsChecked, setIsLightBulbsChecked] = useState(false);
    const [isPestsChecked, setIsPestsChecked] = useState(false);
    const [isSmokeDetectorsChecked, setIsSmokeDetectorsChecked] = useState(false);
    const [isToiletChecked, setIsToiletChecked] = useState(false);
    const [isWasherDryerChecked, setIsWasherDryerChecked] = useState(false);
    const [isLeaksClogsChecked, setIsLeaksClogsChecked] = useState(false);
    return (
        <section className="section-maintenance">
            <Helmet>
                <title>Renaissance Rentals: Maintenance</title>
            </Helmet>
            <div className="container">
                <h2 className="heading"><span className="emphasized">Non-Emergency Maintenance Request Form</span></h2>
                <p>
                    <i>
                        If you are experiencing a true maintenance emergency in your home, please call us at <a
                        href="tel:812-333-2280">812-333-2280</a>. We have a 24/7 Emergency Maintenance Service
                    </i>
                </p>

                <div className="maintenance-options">
                    <p>If the maintenance issue you are experiencing is related to any of the items in this
                        list, please select all that apply...</p>
                    <div className="maintenance-option">
                        <Checkbox label="Air Conditioner/Heat/Furnace" name="aircon" onChange={() => {
                            setIsAirconChecked(!isAirconChecked);
                        }}/>
                        {isAirconChecked ? <div className="maintenance-fix">
                            <p><strong>What do I do if the apartment is not heating or cooling to the correct
                                temperature?</strong></p>
                            <ul>
                                <li>We always recommend checking your thermostat first. Do you have it on the correct
                                    setting (Heat, Cool, Off)? Do you have the fan set on "Auto" or "On?" If it is set
                                    on auto, you will notice the heat or A/C turning on and off throughout the day. If
                                    it is set to "on" the fan will run continuously.
                                </li>
                                <li>
                                    If you have a digital thermostat, you may want to try replacing the batteries.
                                </li>
                                <li>
                                    Another easy thing to check is your gray electric service panel. Make sure the
                                    switches to your Furnace and A/C are in the "On" position. You may even try
                                    switching the proper breaker switch off then back on. If these simple fixes do not
                                    work, go ahead and enter a maintenance request.
                                </li>
                                <li>
                                    Please do NOT store items near, on, or leaning against the furnace or water heater.
                                </li>
                            </ul>
                            <p><strong>What if I feel cool air coming from my vents in the winter when I have the heat
                                on?</strong></p>
                            <ul>
                                <li>Check your thermostat. If the temperature in your apartment is at the temperature
                                    you have set it to, then you should not worry. If it is significantly (more than 5
                                    degrees) colder than what you have it set at, try the same steps from above.
                                </li>
                            </ul>
                            <p><strong>I turn my heat down in the winter and my A/C to a higher temp in the summer when
                                I am not home. How long should it take to reheat or re-cool my apartment once I get
                                home?</strong></p>
                            <ul>
                                <li>This varies depending on the size of your apartment, the conditions outside, and how
                                    many degrees you'd like to increase or decrease the temperature. As a rule of thumb,
                                    the temperature should change at least one degree per hour.
                                </li>
                            </ul>
                        </div> : ""}
                    </div>
                    <div className="maintenance-option">
                        <Checkbox label="Dishwasher" name="dishwasher" onChange={() => {
                            setIsDishwasherChecked(!isDishwasherChecked);
                        }}/>
                        {isDishwasherChecked ? <div className="maintenance-fix">
                            <p><strong>I ran the dishwasher, but the dishes aren't clean. What should I do?</strong></p>
                            <ul>
                                <li>Lightly rinse your dishes and scrape off all food into or trash before you load the
                                    dishes. If food items like pasta or rice harden to your dishes, the dishwasher may
                                    not remove those hardened food items.
                                </li>
                                <li>
                                    Run hot water in your kitchen sink before starting your dishwasher. Once your sink
                                    water is hot, turn your dishwasher on, and shut off the water to your sink.
                                </li>
                                <li>
                                    You may need to clean your dishwasher by placing a 1 quart bowl full of white
                                    vinegar in your bottom dishwasher rack and run your dishwasher through 2 cycles.
                                </li>
                                <li>
                                    Place glasses, bowls, cups, and over-sized serving utensils on the top rack. Plates,
                                    pots, pans, and utensils should be on the bottom.
                                </li>
                                <li><strong>Do not</strong> block the center of the bottom rack where the water sprays
                                    up to the top.
                                </li>
                                <li>Use a high quality dishwasher detergent. Cheaper detergents may leave a film on your
                                    dishes.
                                </li>
                                <li>If you try all of the above and your dishes are still not getting clean, enter a
                                    maintenance request.
                                </li>
                            </ul>
                            <p><strong>There is standing water at the bottom of my dishwasher. What should I
                                do?</strong></p>
                            <ul>
                                <li><strong>NEVER USE DRAINO!!!</strong></li>
                                <li>If you have a garbage disposal, there may be debris in the disposal blocking the
                                    drain line. Clear your disposal of debris and try running the dishwasher again.
                                </li>
                                <li>Check the screen at the bottom of the dishwasher for a blockage.</li>
                                <li>Enter a maintenance request if these suggestions do not drain the water.</li>
                            </ul>
                            <p><strong>How long should it take my dishes to wash in the dishwasher?</strong></p>
                            <ul>
                                <li>This does vary depending on the dishwasher but you can expect it to be in the
                                    washing/rinse cycles for 1.5 hours to 2.5 hours.
                                </li>
                            </ul>
                        </div> : ""}
                    </div>
                    <div className="maintenance-option">
                        <Checkbox label="Electrical" name="electrical" onChange={() => {
                            setIsElectricalChecked(!isElectricalChecked);
                        }}/>
                        {isElectricalChecked ? <div className="maintenance-fix">
                            <p><strong>My outlet isn't operating. My appliance isn't operating. What should I
                                do?</strong></p>
                            <ul>
                                <li>If you have an outlet or appliance that is not operating, you will first want to
                                    check all of your GFI receptacles in the area and push
                                    the &quot;test&quot; then &quot;reset&quot; button. GFI outlets are the outlets that
                                    have the two buttons in the middle.
                                </li>
                                <li>If this doesn't fix your problem, then check your breaker switches in your grey
                                    electric service panel to make sure all switches are in the &quot;on&quot; position.
                                    You may have to turn the switch off then back on.
                                </li>
                                <li>Enter a maintenance request if these suggestions do not solve the problem.</li>
                            </ul>
                        </div> : ""}
                    </div>
                    <div className="maintenance-option">
                        <Checkbox label="Garbage Disposal" name="garbageDisposal" onChange={() => {
                            setIsGarbageDisposalChecked(!isGarbageDisposalChecked);
                        }}/>
                        {isGarbageDisposalChecked ? <div className="maintenance-fix">
                            <p><strong>How do I make sure my disposal works properly?</strong></p>
                            <ul>
                                <li>Avoid putting certain items in the disposal. You may be surprised that egg shells,
                                    rice, pasta, potato skins, banana peels, corn husks, corn cobs, onion skins, and
                                    many stringy vegetables are VERY BAD for disposals. You'll also want to avoid
                                    putting any string, plastic, glass, or metal in the disposal.
                                </li>
                                <li>Always have the water running in your sink while using the disposal.</li>
                                <li>Cut any food items that will be put into the disposal into small pieces.</li>
                            </ul>
                            <p><strong>What should I do if my disposal will not turn on?</strong></p>
                            <ul>
                                <li>If your garbage disposal is not working (no sound at all), hit
                                    the &quot;reset&quot; button on the side or bottom (usually at the bottom) of your
                                    garbage disposal under the sink. Now try turning on the disposal. If you hear the
                                    disposal trying to work, but the blades do not rotate, most likely there is
                                    something jammed in the disposal. If that is the case, enter a maintenance request.
                                    If you heard no noise when you flipped the switch, try the next couple steps.
                                </li>
                                <li>There are GFI outlets in the kitchen that you should try to reset by pushing
                                    the &quot;test&quot; then &quot;reset&quot; buttons. Check all GFI outlets in the
                                    kitchen. You may find one that needed to be reset. Try turning on the disposal
                                    again.
                                </li>
                                <li>Still no luck? Lastly, check your gray electric service panel to make sure that all
                                    breaker switches are in the &quot;on&quot; position and that no breakers have been
                                    tripped to the &quot;off&quot; position. If the disposal will not turn on after
                                    taking these steps, go ahead and enter a maintenance request.
                                </li>
                            </ul>
                            <p><strong>What if my disposal makes a horrible sound when I turn it on?</strong></p>
                            <ul>
                                <li>If your garbage disposal sounds horrible when turned on, something is most likely
                                    stuck inside. Turn off the disposal and enter a maintenance request.
                                </li>
                            </ul>
                        </div> : ""}
                    </div>
                    <div className="maintenance-option">
                        <Checkbox label="Light Bulbs" name="lightBulbs" onChange={() => {
                            setIsLightBulbsChecked(!isLightBulbsChecked);
                        }}/>
                        {isLightBulbsChecked ? <div className="maintenance-fix">
                            <p><strong>My light bulb is out. Do I replace it?</strong></p>
                            <ul>
                                <li>In most cases, yes. If a bulb is out of your reach, please leave a replacement bulb
                                    on your kitchen counter and enter a maintenance request. If you can reach it, please
                                    replace it.
                                </li>
                            </ul>
                        </div> : ""}
                    </div>
                    <div className="maintenance-option">
                        <Checkbox label="Pests" name="pests" onChange={() => {
                            setIsPestsChecked(!isPestsChecked);
                        }}/>
                        {isPestsChecked ? <div className="maintenance-fix">
                            <p><strong>There are bugs in my apartment. What should I do?</strong></p>
                            <ul>
                                <li>The bad news is brand new buildings have bugs and old buildings have bugs. We are on
                                    their turf. Whether you are on the ground floor or 3rd floor, bugs seem to find a
                                    way inside (especially in spring). It is very unlikely that you will never see a bug
                                    (especially spiders and ants) in your apartment, but there are things you can do to
                                    reduce the pests in your home.
                                </li>
                                <li>Make sure your home is clean! Trash, food, and spills attract pests. Anything with
                                    protein will attract ants (that includes toothpaste).
                                </li>
                                <li>If you have a clean home and you still notice bugs, purchase some bug spray and
                                    traps. For ants, we recommend the Terro brand product. If you notice the bugs in a
                                    particular area, spray that area with an indoor bug spray once a month or once every
                                    other month. Also, purchase liquid ant traps, such as Terro liquid ant baits. We
                                    usually have some in stock in the office so you can stop by and pick a few up!
                                </li>
                                <li>Spiders are tricky. They seem to show up no matter what we do. If you have a hose
                                    attachment on your vacuum, you can suck them up. Or you can use the tried and true
                                    grab with a paper towel or tissue. There are spider sprays. Those will kill the
                                    visible spiders, but they generally do not prevent them from coming back.
                                </li>
                                <li>After you have tried the above suggestions and still have a serious bug problem,
                                    enter a maintenance request.
                                </li>
                            </ul>
                        </div> : ""}
                    </div>
                    <div className="maintenance-option">
                        <Checkbox label="Smoke Detectors & Fire Extinguishers" name="smokeDetectors" onChange={() => {
                            setIsSmokeDetectorsChecked(!isSmokeDetectorsChecked);
                        }}/>
                        {isSmokeDetectorsChecked ? <div className="maintenance-fix">
                            <p><strong>My smoke detector is making a chirping noise often, what should I do?</strong>
                            </p>
                            <ul>
                                <li>This means the battery is low. Please replace the battery. They use a 9v. If you
                                    cannot reach your smoke detector, please leave a 9v battery on the kitchen counter
                                    and give us a call.
                                </li>
                            </ul>
                            <p><strong>If you're cooking and the smoke alarm goes off:</strong></p>
                            <ul>
                                <li>Wave something near the smoke alarm to clear the air</li>
                                <li>Open a window or two</li>
                                <li>Turn on a fan</li>
                                <li>Do not take it down!</li>
                                <li>*Make sure your exhaust vent is on while cooking</li>
                            </ul>
                            <p><strong>If it gets knocked down for any reason or starts malfunctioning in any
                                way:</strong></p>
                            <ul>
                                <li>Please let us know as soon as possible and we will fix/replace it
                                </li>
                            </ul>
                            <p><strong>How do I use the fire extinguisher in case of emergency?</strong></p>
                            <ul>
                                <li>To use, remove the pin, and press down on the nozzle-aim at the bottom of the
                                    flames. <strong>Please do not "test" the fire extinguisher by firing it. This
                                        discharges the unit and makes it unusable.</strong>
                                </li>
                            </ul>
                        </div> : ""}
                    </div>
                    <div className="maintenance-option">
                        <Checkbox label="Toilet" name="toilet" onChange={() => {
                            setIsToiletChecked(!isToiletChecked);
                        }}/>
                        {isToiletChecked ? <div className="maintenance-fix">
                            <p><strong>My toilet is stopped up/clogged. What should I do?</strong></p>
                            <ul>
                                <li><strong>Step 1</strong> - Plunge, plunge, plunge your toilet.</li>
                                <li><strong>Step 2</strong> - Plunge again!</li>
                                <li>When we receive a call for a clogged toilet, almost every time our maintenance team
                                    just plunges and the toilet is fixed. If our maintenance team comes to your home to
                                    simply plunge your toilet, <strong>you may be charged for this service</strong>.
                                    There are plungers in each of our offices for you to use. We recommend that you
                                    invest in a good plunger when renting an apartment.
                                </li>
                                <li>Facial tissues, paper towels, and tampons do not dissolve in water so avoid placing
                                    any of those items in the toilet.
                                </li>
                            </ul>
                            <p><strong>Is your toilet constantly running, overflowing, or about to overflow?</strong>
                            </p>
                            <ul>
                                <li>Please turn off the water valve to your toilet. This shut-off valve is located at
                                    the bottom of the toilet near the floor or wall. <strong>Turn it to the right to
                                        shut it off.</strong></li>
                                <li>If the toilet was about to overflow, it probably needs to be plunged.</li>
                                <li>If the toilet is constantly running, go ahead and enter a maintenance request.</li>
                            </ul>
                        </div> : ""}
                    </div>
                    <div className="maintenance-option">
                        <Checkbox label="Washer & Dryer" name="washerDryer" onChange={() => {
                            setIsWasherDryerChecked(!isWasherDryerChecked);
                        }}/>
                        {isWasherDryerChecked ? <div className="maintenance-fix">
                            <p><strong>My dryer isn't drying my clothes. What should I do?</strong></p>
                            <ul>
                                <li>Make sure that after every dryer load, you take the time to clean the lint filter.
                                    Keeping this free of obstruction helps improve air circulation making the dryer work
                                    more efficiently.
                                </li>
                                <li>Do not overload the dryer.</li>
                                <li>Clothes should go through the spin cycle in the washing machine before going into
                                    the dryer. If the clothes are completely drenched and dripping wet when you place
                                    them in the dryer, they will not dry properly.
                                </li>
                                <li>Check the setting. There is often a &quot;Fluff Air&quot; setting which will not dry
                                    clothes as quickly as the &quot;Delicate&quot; or &quot;Regular&quot; setting.
                                </li>
                                <li>If those suggestions do not help, mostly like the exterior dryer vent is clogged
                                    with lint or the dryer hose has bent. Go ahead and enter a maintenance request if
                                    that is the case.
                                </li>
                            </ul>
                            <p><strong>My washing machine is not working properly</strong></p>
                            <ul>
                                <li>We often get calls from residents stating that their clothes do not get clean. If
                                    you are new to washing clothes or have a tough stain we recommend checking out
                                    About.com and search for &quot;Tips and tutorials to do Laundry.&quot; There are
                                    many helpful articles on that website. In general, the washer is performing
                                    correctly when you turn the washing machine on and it agitates the clothes and runs
                                    through all of the cycles. In the event a stain does not come out, it does not
                                    necessarily indicate a problem with the washer.
                                </li>
                                <li>If you are only washing a few things, try not to set the water level too high. Too
                                    much water and not enough clothes may cause the water to spill over the sides of the
                                    washing machine.
                                </li>
                                <li>If your washing machine will not agitate the clothes, go ahead and enter a
                                    maintenance request.
                                </li>
                            </ul>
                        </div> : ""}
                    </div>
                    <div className="maintenance-option">
                        <Checkbox label="Water Leaks & Clogs" name="leaksClogs" onChange={() => {
                            setIsLeaksClogsChecked(!isLeaksClogsChecked);
                        }}/>
                        {isLeaksClogsChecked ? <div className="maintenance-fix">
                            <p><strong>I have a water leak. What should I do?</strong></p>
                            <ul>
                                <li>If you have water leaking from your toilet, kitchen or bath sinks, turn the water
                                    shut-off valve to the leaking fixture &quot;off&quot; until the water stops. The
                                    shut-off valve for your toilet is near the floor and wall, while the sink valves are
                                    under your sink cabinets near the back of the cabinet wall. Turn the valve clockwise
                                    to turn the water off.
                                </li>
                                <li>If you cannot determine the source of the leak, but believe it is coming from your
                                    apartment, you can shut off the water to the entire apartment. In many of the
                                    apartments, the main water shut-off valve is located near your water heater-either a
                                    lever or a knob. Please call us if you have a leak.
                                </li>
                                <li><u>TIP</u>: Always use your exhaust fan in your bathroom when taking a shower. This
                                    will help prevent damage to the walls due to condensation.
                                </li>
                            </ul>
                            <p><strong>How do I prevent drains in my apartment from clogging?</strong></p>
                            <ul>
                                <li>The best advice is to not put anything down the drain that shouldn&#x27;t go down
                                    the drain. Hair, cooking grease, food debris, string, and any other foreign object
                                    are culprits for drain clogs.
                                </li>
                                <li>Plug the drain. Then simply fill your sink or tub with the hottest possible water
                                    every couple of weeks, let the drain out, and then follow with about thirty seconds
                                    of hot water from the tap. This helps &quot;blow out&quot; the slime and junk that
                                    builds up and causes drain clogs. While not the best thing for your energy savings,
                                    it is a tried-and-true method for preventing future drain clogs.
                                </li>
                            </ul>
                            <p><strong>What do I do if water is draining slowly or the drain is completely
                                clogged?</strong></p>
                            <ul>
                                <li><strong>DO NOT USE DRANO</strong></li>
                                <li>Enter a maintenance request</li>
                            </ul>
                        </div> : ""}
                    </div>
                    <div className="maintenance-action">
                        <p>If you were not able to troubleshoot the issue yourself, please click the button below to
                            submit
                            your maintenance request. You will be directed to log-in to your Appfolio account, where you
                            can
                            then submit your maintenance request</p>


                        <Button variant="primary" size="large" onClick={() => {
                            window.location.href = "https://renaissancerentals.appfolio.com/connect/users/sign_in";
                        }}>
                            Submit your Maintenance Request
                        </Button>
                    </div>
                </div>

            </div>
        </section>
    );
}
