import {UtilityEmail} from "../data/UtilityEmail";
import Api from "./Api";
import {get} from "@renaissancerentals/renaissance-component";
import AdminApi from "./AdminApi";

export interface UnitAddress {
    id: string;
    address: string;
    zipcode: string;
}

export interface Address {
    unitId: string;
    address: string;
}

export interface UtilitiesEmail {
    utilities: Utility[];
    property: PropertyEmail;
}

export interface PropertyEmail {
    email: string;
}

export interface Utility {
    name: string;
    type: string;
    propertyEmail: string;
}

export const getAllUnitAddresses = async (): Promise<UnitAddress[]> => {
    let response = await AdminApi.get("units/all/addresses");
    return await response.data;
}

export const getUnitUtilitiesAndEmail = async (unitId: String): Promise<UtilitiesEmail> => {
    let response = await get("units/" + unitId + "?projection=utilities");
    return await response.data.floorplan;
}

export const sendUtilityConfirmationEmail = async (message: UtilityEmail) => {
    let response = await Api.post("mail/utility", message);
    return await response.data;
};
