import React, {Fragment, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import "./assets/EmploymentsPage.scss"
import {JobVacancy} from "../data/JobVacancy";
import {getJobs} from "../services/EmploymentService";
import {Col, Icon, Paper, Table} from "@contentmunch/muncher-ui";
import {enumToString, toDate} from "@renaissancerentals/renaissance-component";

export const EmploymentsPage: React.FC = () => {
    const [jobVacancies, setJobVacancies] = useState<JobVacancy[]>([]);
    useEffect(() => {
        getJobs().then(data => setJobVacancies(data));
    }, []);

    const rows = (): Col[][] => {
        const jobData: Col[][] = [];
        jobVacancies.forEach(jobVacancy => {

            jobData.push([

                {
                    value: jobVacancy.title,
                    content: jobVacancy.title,
                },
                {
                    value: jobVacancy.validThrough,
                    content: toDate(jobVacancy.validThrough)
                },
                {
                    value: jobVacancy.salary + "",
                    content: jobVacancy.salary + "/" + enumToString(jobVacancy.salaryType)
                },
                {
                    value: jobVacancy.startDate,
                    content: jobVacancy.startDate
                },
                {
                    value: "", content:
                        <a href={"/employment/" + jobVacancy.id}>
                            <Icon name="link" orientation="right">View Details</Icon>
                        </a>
                }
            ])
        });
        return jobData;
    };
    return (
        <Fragment>
            <Helmet>
                <title>Renaissance Rentals: Employment</title>
            </Helmet>
            <section className="section-employments">
                <h2>Employment Opportunities</h2>

                <div className="container">
                    <div className="div-history">
                        <Paper>
                            <Table sortBy={{index: 1}} excludeDownload={true}>
                                {{
                                    header: [
                                        {name: "Title", title: "Title"},
                                        {name: "Valid Through", title: "Valid Through", visibility: "large-screen"},
                                        {name: "Salary", title: "Salary", visibility: "large-screen"},
                                        {name: "Start Date", title: "Start Date", visibility: "large-screen"},
                                        {
                                            name:
                                                <p></p>

                                        },

                                    ], rows: rows()
                                }}
                            </Table>
                        </Paper>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};