export enum LeasingOfficeType {
    OFF_SITE = "OFF_SITE", ON_SITE = "ON_SITE"
}

export interface PropertyLeaseDetails {
    id: string;
    name: string;
    active: boolean;
    leasingOfficeType: LeasingOfficeType;
}

export interface PropertyData {
    name: string;
    active: boolean;
    floorplans: FloorplanData;
}

export interface FloorplanData {
    id: string;
    name: string;
    active: boolean;
    bedroom: number;
    bathroom: number;
    coverImage: string;
    featured: boolean;
    threeSixtyVideoTourLink: string;
    videoTourLink: string;
    units: UnitData[];

}

export interface UnitData {
    rent: number;
    squareFoot: number;
    active: boolean;
}