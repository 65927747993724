import React, {useEffect, useState} from 'react';
import "./assets/Blogs.scss";

import {Link, useLocation} from "react-router-dom";
import {Blog} from "../components/blog/data/Blog";
import {BlogSearchBar} from "../components/blog/BlogSearchBar";
import {BlogsSection} from "../components/blog/sections/BlogsSection";
import {Button} from "@contentmunch/muncher-ui";
import {Helmet} from "react-helmet-async";
import {getRecentBlogs, search} from "../services/BlogService";

export const BlogsPage: React.FC<BlogsPageProps> = ({label, query}) => {
    const [blogs, setBlogs] = useState<Blog[]>([]);
    const [nextPageToken, setNextPageToken] = useState("");

    const searchParams = new URLSearchParams(useLocation().search);
    const pageToken = searchParams.get("pageToken");
    const isEmpty = (param: any): boolean => {
        return param === null || param === undefined || param === "";
    }

    const getParam = (param: string): string => {
        const value = searchParams.get(param);
        return value === null ? "" : value;

    }
    const queryParam: string = isEmpty(getParam("q")) ? (query ? query : "") : getParam("q");
    const labelParam: string = isEmpty(getParam("label")) ? (label ? label : "") : getParam("label");
    useEffect(() => {
        (isEmpty(queryParam) ? getRecentBlogs(12, labelParam, pageToken) : search(12, queryParam, pageToken))
            .then((data) => {
                setBlogs(data.blogs);
                setNextPageToken(data.nextPageToken);

            });

    }, [queryParam, labelParam, pageToken]);


    const printHeading = () => {
        if (!isEmpty(queryParam)) {
            return <h4>Search results for: {queryParam}</h4>;
        }
        if (labelParam !== "") {
            return <h4>Label: {labelParam}</h4>;
        }
    };
    return (
        <div className="container">
            <Helmet>
                <title>Renaissance Rentals: Blogs</title>
            </Helmet>
            <div className="section-blogs-page">
                <BlogSearchBar/>
                {printHeading()}
                <BlogsSection blogs={blogs}/>
                <div className="blogs-page--footer">
                    <Button variant="secondary" disabled={nextPageToken === ""}>
                        {
                            nextPageToken === "" ? "Older Posts »" :
                                <Link to={"/blogs?pageToken=" + nextPageToken}>Older Posts »</Link>
                        }
                    </Button>
                </div>
            </div>
        </div>
    );
}

export interface BlogsPageProps {
    query?: string;
    label?: string;
}
