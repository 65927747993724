import React from 'react';
import logo from './assets/Logo.png';
import './assets/Logo.scss';
import {Link} from "react-router-dom";

export const Logo: React.FC = () => {
    return (
        <div className="logo">
            <Link to="/"><img src={logo} alt="renaissance rentals"/></Link>
        </div>
    )
};