import React, {useEffect, useState} from "react";
import {
    Address,
    getAllUnitAddresses,
    getUnitUtilitiesAndEmail,
    sendUtilityConfirmationEmail,
    Utility
} from "../services/UtilityService";
import {Button, Checkbox, Input, Spinner, TextInputDropdown} from "@contentmunch/muncher-ui";
import "./assets/UtilityConfirmationPage.scss";
import {Helmet} from "react-helmet-async";
import {Captcha} from "@renaissancerentals/renaissance-component";
import {UtilityEmail} from "../data/UtilityEmail";

export const UtilityConfirmationPage: React.FC = () => {
    const [unitAddress, setUnitAddress] = useState<Address[]>([]);
    const [showContent, setShowContent] = useState(false);
    const [placeHolder, setPlaceHolder] = useState("Loading data, please wait...");
    const [currentAddresses, setCurrentAddresses] = useState<Address[]>([]);
    const [selectedAddress, setSelectedAddress] = useState<Address>({} as Address);
    const [content, setContent] = useState("");
    const [residentUtilities, setResidentUtilities] = useState<Utility[]>([]);
    const [propertyEmail, setPropertyEmail] = useState("mail@renaissancerentals.com");

    const [isUtilitiesLoaded, setIsUtilitiesLoaded] = useState(false);

    const [submitted, setSubmitted] = useState(false);
    const [captchaResponse, setCaptchaResponse] = useState("");
    const [captchaUncheckedError, setCaptchaUncheckedError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionError, setSubmissionError] = useState(false);
    const [submissionErrorMessage, setSubmissionErrorMessage] = useState("Message Failed!");
    const [submissionComplete, setSubmissionComplete] = useState(false);
    const handleInputChange = (query: string) => {
        setContent(query);
        const filteredAddresses = unitAddress.filter(address =>
            address.address.replace(/\s*/g, "").search(new RegExp(query.replace(/\s*/g, ""), "i")) > -1
        );

        if (!filteredAddresses || filteredAddresses.length < 1) {
            setShowContent(false);
        }
        setCurrentAddresses(filteredAddresses);

    }

    const handleClick = (clickedAddress: Address) => {
        setContent(clickedAddress.address);
        setSelectedAddress(clickedAddress);
        setShowContent(false);

        getUnitUtilitiesAndEmail(clickedAddress.unitId).then(utilitiesEmail => {

            if (utilitiesEmail.property.email)
                setPropertyEmail(utilitiesEmail.property.email);
            setResidentUtilities(utilitiesEmail.utilities.filter(
                utility =>
                    utility.type === "RESIDENT_UTILITY" &&
                    utility.name !== "internet" &&
                    utility.name !== "trash removal" &&
                    utility.name !== "lawn care & landscaping"
            ));
            setIsUtilitiesLoaded(true);
        });
    };
    useEffect(() => {
        getAllUnitAddresses()
            .then(data => {
                const addresses: Address[] = data.map(unitAddress => {
                    return {unitId: unitAddress.id, address: unitAddress.address + " " + unitAddress.zipcode}
                });
                setUnitAddress(addresses);
                setPlaceHolder("Start typing your address");
            });

    }, []);


    const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        setCaptchaUncheckedError("");
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() && captchaResponse !== "") {
            setIsSubmitting(true);
            const mailObject: UtilityEmail = {
                captchaResponse,
                name: form.fullName.value,
                email: form.email.value,
                to: propertyEmail,
                unitId: selectedAddress.unitId,
                unitAddress: selectedAddress.address,
                dukeAccountNumber: form.dukeAccountNumber?.value,
                vectrenAccountNumber: form.vectrenAccountNumber?.value,
                waterFormSubmitted: form.waterFormSubmitted?.checked
            };
            sendUtilityConfirmationEmail(mailObject)
                .then(() => {
                    setSubmissionComplete(true);
                    setSubmitted(true);
                })
                .catch((e: any) => {
                    if (e.response && e.response.data) {
                        setSubmissionErrorMessage(e.response.data.message);
                    }
                    setSubmissionError(true);
                })
                .finally(() => {
                    setIsSubmitting(false);

                });
        } else {
            setCaptchaUncheckedError("Please Check Captcha!");
        }

    }


    return (
        <>
            <Helmet>
                <title>Renaissance Rentals: Utility Setup Confirmation Form</title>
                <meta name="description"
                      content="Form for future Renaissance Rentals residents to use to confirm setup of required resident paid utilities"/>
            </Helmet>
            <section className="section-utility-confirmation">

                <div className="container">
                    <div className="div-utility-confirmation">
                        <h2 className="heading">Utility Setup Confirmation Form</h2>

                        <p className="information">Please complete this form to confirm you have set up the required
                            utilities for your new home. </p>
                        <form onSubmit={submitHandler}>

                            <div className="form-element">
                                <Input label="Your Full Name" name="fullName" required/>
                            </div>
                            <div className="form-element">
                                <Input label="Email Address" name="email" required type="email"/>
                            </div>
                            <div className="form-element">
                                <TextInputDropdown
                                    handleInputChange={handleInputChange}
                                    inputPlaceHolder={placeHolder}
                                    showContent={showContent}
                                    setShowContent={setShowContent}
                                    value={content}
                                    required={true}
                                    label="Your Future Address"
                                >
                                    {currentAddresses.map(address => (
                                        <div className="content--item" key={address.address}
                                             onClick={() => handleClick(address)}>
                                            {address.address}
                                        </div>
                                    ))}

                                </TextInputDropdown>
                            </div>
                            {
                                isUtilitiesLoaded ?
                                    <div className="secondary">
                                        {residentUtilities.length === 0 ?
                                            <p><b>You are not required to put the Gas, Water, or Electric service into
                                                your name</b></p> :
                                            <>
                                                <p>
                                                    {
                                                        residentUtilities.length === 1 ?
                                                            <b>Here is the required utility for your future home:</b> :
                                                            <b>Here are the required utilities for your future home:</b>
                                                    }

                                                </p>
                                                <ul>
                                                    {residentUtilities.some(utility => utility.name === "gas") ?
                                                        <li>
                                                            <p>Vectren - Center Point Energy (Gas): <a
                                                                href="tel:8002271376">800-227-1376</a> | <a
                                                                href="https://www.vectren.com/start">online setup
                                                                form</a>
                                                            </p>
                                                            <div className="form-element">
                                                                <Input label="Enter Account Number - "
                                                                       name="vectrenAccountNumber"
                                                                       type="text"
                                                                       required/>
                                                            </div>
                                                        </li>
                                                        : ""}
                                                    {residentUtilities.some(utility => utility.name === "electric") ?
                                                        <li>
                                                            <p>Duke (Electricity): <a
                                                                href="tel:8005212232">800-521-2232</a> | <a
                                                                href="https://www.duke-energy.com/home/start-stop-move">online
                                                                setup
                                                                form</a>
                                                            </p>
                                                            <div className="form-element">
                                                                <Input label="Enter Account Number - "
                                                                       name="dukeAccountNumber"
                                                                       type="text"
                                                                       required/>
                                                            </div>
                                                        </li>
                                                        : ""}
                                                    {residentUtilities.some(utility => utility.name === "water") ?
                                                        <li>
                                                            <p>CBU (Water): <a
                                                                href="tel:8123493930">812-349-3930</a> | <a
                                                                href="https://bloomington.in.gov/utilities_forms/index.php?form=individualcustomercontract">online
                                                                setup form</a>
                                                            </p>
                                                            <div className="form-element">
                                                                <Checkbox label="Please check
                                                                            the
                                                                            box below confirming you have submitted the
                                                                            online form. An account # is not
                                                                            required." name="waterFormSubmitted"
                                                                          required={true}/>

                                                            </div>
                                                        </li>
                                                        : ""}
                                                </ul>

                                                <Captcha setCaptchaResponse={setCaptchaResponse}
                                                         error={captchaUncheckedError}/>

                                                <div className="form-element">
                                                    {submissionComplete ?
                                                        <p className="text-success message">Message Sent!</p> : ""}
                                                    {submissionError ?
                                                        <p className="text-danger message">{submissionErrorMessage}</p> : ""}
                                                </div>

                                                <div className="form-element form-submit">
                                                    <Button variant="primary" size="large" type="submit"
                                                            disabled={submitted}>
                                                        Submit
                                                    </Button>
                                                    {isSubmitting ? <Spinner size="small"/> : ""}
                                                </div>
                                            </>
                                        }
                                    </div> : <></>
                            }`
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};

