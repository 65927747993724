import React, {useState} from 'react';
import './assets/Hero.scss';
import {Button, DropdownButton, Icon} from "@contentmunch/muncher-ui";
import {DateInput} from "../../data/Calendar";
import {dateInputArray} from "../../utils/Utils";

export const Hero: React.FC = () => {
    const [showBedrooms, setShowBedrooms] = useState(false);
    const [showMoveInDate, setShowMoveInDate] = useState(false);
    const [selectedMoveInDate, setSelectedMoveInDate] = useState<DateInput>();
    const [selectedBedroom, setSelectedBedroom] = useState(-1);
    const bedrooms: BedroomInput[] = [{no: 0, name: "Studio"},
        {no: 1, name: "1 Bedroom"}, {no: 2, name: "2 Bedrooms"},
        {no: 3, name: "3 Bedrooms"}, {no: 4, name: "4 Bedrooms"}];


    const handleBedroomClick = (bedroom: number) => {
        setSelectedBedroom(bedroom);
        setShowBedrooms(false);
    };
    const handleMoveInDateClick = (moveInDate: DateInput) => {
        setSelectedMoveInDate(moveInDate);
        setShowMoveInDate(false);
    };

    const searchUrl = () => `/floorplans?bedrooms=${selectedBedroom}&availability=${selectedMoveInDate ? selectedMoveInDate.index : ""}`;

    return (
        <section className="section-hero">
            <div className="hero-left">
                <div className="hero-search">
                    <header>
                        <div className="content">Jumpstart Your Apartment Search</div>
                    </header>
                    <div className="hero-input">
                        <div className="hero-input-element">
                            <h6>Bedrooms</h6>
                            <DropdownButton
                                element={<h6>{selectedBedroom < 0 ?
                                    <span>&nbsp;</span> : bedrooms[selectedBedroom].name}</h6>}
                                showContent={showBedrooms}
                                setShowContent={(flag) => {
                                    setShowBedrooms(flag);
                                }}
                                size="medium"
                                withDropIcon={true}
                                drop="middle"
                            >
                                {
                                    bedrooms.map(bedroom =>
                                        <Button key={(bedroom.no)}
                                                onClick={() => handleBedroomClick(bedroom.no)}>{bedroom.name}</Button>)}

                            </DropdownButton>
                        </div>
                        <div className="hero-input-element">
                            <h6>Move-in date</h6>
                            <DropdownButton
                                element={<h6>{selectedMoveInDate ? selectedMoveInDate.name : <span>&nbsp;</span>}</h6>}
                                showContent={showMoveInDate}
                                setShowContent={(flag) => {
                                    setShowMoveInDate(flag);
                                }}
                                size="medium"
                                withDropIcon={true}
                                drop="middle"
                            >
                                {
                                    dateInputArray().map(moveInDate =>
                                        <Button key={(moveInDate.index)}
                                                onClick={() => handleMoveInDateClick(moveInDate)}>{moveInDate.name}</Button>)}

                            </DropdownButton>
                        </div>
                    </div>
                    <div className="hero-button">
                        <Button variant="primary" onClick={() => {
                            window.location.href = searchUrl();
                        }}><a href={searchUrl()}>Search</a></Button></div>
                </div>
            </div>
            <div className="hero-right">
                <div className="hero-right-content">
                    <header>Quality <Icon name="dot" size="medium"/> Variety <Icon name="dot" size="medium"/> Location
                    </header>
                    <blockquote>
                        Rent the Best<br/> in Bloomington
                    </blockquote>
                </div>
            </div>
        </section>
    );
}

interface BedroomInput {
    no: number;
    name: string;
}

