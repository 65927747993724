import React from 'react';
import './assets/BlogsSection.scss';
import {Blog} from "../data/Blog";
import {BlogCard} from "../BlogCard";


export const BlogsSection: React.FC<BlogsSectionProps> = ({blogs}) => {

    return (
        <section className="section-blogs">
            <div className="div-blogs">
                {blogs.map((blog, index) => (
                    <BlogCard key={index} blog={blog}/>
                ))}
            </div>
        </section>
    );
}

export interface BlogsSectionProps {
    blogs: Blog[];
}