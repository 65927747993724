import React from "react";
import "./assets/LeasingOfficeCard.scss";
import {LeasingOffice} from "../../data/LeasingOffice";
import {filterProperties, officeTypeToString} from "../../services/LeasingOfficeService";
import {addressToGoogleMapLink, formatPhoneNumber} from "@renaissancerentals/renaissance-component";
import {getAsset} from "../../services/AssetService";

export const LeasingOfficeCard: React.FC<LeasingOfficeCardProps> = ({leasingOffice}) => {
    return (
        <div className="leasing-office-card">
            <p>Home office for:</p>
            {filterProperties(leasingOffice.properties).map(property =>
                <div className="leasing-office-card-two-column" key={"leasing-office-card-property-" + property.id}>
                    <p className="leasing-office-card--title">
                        {property.name}
                    </p>
                    <p className="leasing-office-card--value"><i>({officeTypeToString(property.leasingOfficeType)})</i></p>
                </div>
            )}
            <p className="leasing-office-card--link"><a
                href={addressToGoogleMapLink(leasingOffice.address, leasingOffice.zipcode)}>{leasingOffice.address + " " + leasingOffice.zipcode}</a>
            </p>
            <p className="leasing-office-card--emphasize">
                <a href={"tel:" + leasingOffice?.phone}>
                    {formatPhoneNumber(leasingOffice?.phone)}
                </a><br/>
                {leasingOffice?.officeHours}
            </p>
            <div className="leasing-office-card-map--image">
                {leasingOffice?.officeMapLandscape ?
                    <img src={getAsset(leasingOffice.officeMapLandscape)} alt={leasingOffice.name}/> : ""}

            </div>
            <p className="leasing-office-card--description">
                {leasingOffice?.direction}
            </p>
            <div className="leasing-office-card--image">
                {leasingOffice?.officeImage ?
                    <img src={getAsset(leasingOffice.officeImage)} alt={leasingOffice.name}/> : ""}

            </div>
        </div>
    );
};

export interface LeasingOfficeCardProps {
    leasingOffice: LeasingOffice;
}