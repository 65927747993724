import React, {useCallback, useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import './assets/BlogSearchBar.scss';
import {Button, Input} from "@contentmunch/muncher-ui";

export const BlogSearchBar: React.FC = () => {
    const [query, setQuery] = useState("");
    let navigate = useNavigate();

    const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    };
    const handleSearchButtonClick = useCallback(() => {
        navigate("/blogs?q=" + query);
    }, [navigate, query]);

    const enterFunction = useCallback((event:any) => {
        if (event.key === 'Enter') {
            if (query !== "") {
                handleSearchButtonClick();
            }
        }
    }, [handleSearchButtonClick, query]);
    useEffect(() => {
        document.addEventListener("keydown", enterFunction, false);

        return () => {
            document.removeEventListener("keydown", enterFunction, false);
        };
    }, [enterFunction]);
    return (
        <div className="blog-search-bar">
            <div className="blog-search-bar--element">
                <Input
                    name="query"
                    type="text"
                    placeholder="Search this blog"
                    onChange={e => handleQueryChange(e)}/>

                <Button disabled={query === ""} onClick={handleSearchButtonClick} variant="primary">Search</Button>
            </div>
            <h5>
                <Link to="/blogs?label=Featured">Featured</Link>&nbsp;|&nbsp;
                <Link to="/blogs?label=Apartment%20Spotlight">Apartment Spotlight</Link>&nbsp;|&nbsp;
                <Link to="/blogs?label=Employee%20Spotlight">Employee Spotlight</Link>
            </h5>

        </div>
    );
}
