import React, {useEffect, useState} from "react";
import "./assets/Blog.scss";
import {useParams} from "react-router-dom";
import {Blog} from "../components/blog/data/Blog";
import {BlogSearchBar} from "../components/blog/BlogSearchBar";
import {BlogContent} from "../components/blog/BlogContent";
import {BlogHighlightSection} from "../components/blog/sections/BlogHighlightSection";
import {Helmet} from "react-helmet-async";
import {getBlog} from "../services/BlogService";

export const BlogPage: React.FC = () => {
    const blogParam = useParams();
    const [blog, setBlog] = useState<Blog>();

    useEffect(() => {
        if (blogParam.blogId) {
            Promise.all([getBlog(blogParam.blogId)])
                .then((data) => {
                    setBlog(data[0]);
                });
        }

    }, [blogParam.blogId]);
    return (
        <>
            <Helmet>
                <title>Renaissance Rentals: {blog?.title ? blog?.title : "Blog"}</title>
            </Helmet>
            <div className="container">
                <section className="section-blog--page">
                    <BlogSearchBar/>
                    <h1 className="blog-page--heading">{blog?.title}</h1>
                    <BlogContent content={blog?.content}/>
                </section>
            </div>
            <BlogHighlightSection sectionTitle="Recent Blogs..."/>
        </>
    );
};

export interface BlogParam {
    blogId: string
}
