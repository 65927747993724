import React, {useState} from "react";
import "./assets/Spotlight.scss";
import bedIcon from "./assets/bed-icon.png";
import bathIcon from "./assets/bath-icon.png";
import {enumToString, rangeFrom} from "@renaissancerentals/renaissance-component";
import {Button, Spinner} from "@contentmunch/muncher-ui";
import {SpotlightTextSkeleton} from "./SpotlightTextSkeleton";
import {getAssetUrl} from "@renaissancerentals/renaissance-component/lib/asset/service/AssetService";
import {FloorplanSpotlight} from "../floorplan/data/Floorplan";
import {capitalizeFirstLetter} from "@renaissancerentals/renaissance-component/lib/utils/Utils";

export const Spotlight: React.FC<SpotlightProps> = ({floorplan}) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    return (
        <div className="spotlight">
            <div className="left">
                {isImageLoaded ?
                    <div className="unit-spotlight">
                        <header>{floorplan.name} at {floorplan.property.name}</header>
                        <p>{floorplan.metaDescription}</p>
                        <div className="unit-metadata">
                            <div className="metadata">
                                <img src={bedIcon} alt="bed"/>
                                <p className="value">{floorplan.bedroom}</p>
                            </div>
                            <div className="metadata">
                                <img src={bathIcon} alt="bath"/>
                                <p className="value">{floorplan.bathroom}</p>
                            </div>
                            <div className="metadata">
                                <p>Sq. Ft.</p>
                                <p className="value">{rangeFrom(floorplan.units, "squareFoot")}</p>
                            </div>
                            <div className="metadata">
                                <p>Apartment Style: </p>
                                <p className="value">{capitalizeFirstLetter(enumToString(floorplan.style))}</p>
                            </div>
                            <div className="metadata">
                                <p>Monthly Rate: </p>
                                <p className="value">${rangeFrom(floorplan.units, "rent")}</p>
                            </div>
                            <div className="metadata">
                                <Button variant="primary" onClick={() => {
                                    window.location.href = "/floorplans/" + floorplan.id;
                                }}>Learn More</Button>
                            </div>
                        </div>
                    </div> : <SpotlightTextSkeleton/>
                }
            </div>
            <div className="right">
                <div className="div-spotlight-image">
                    {isImageLoaded ? "" : <Spinner size="small"/>}
                    <img className="spotlight-image" src={getAssetUrl(floorplan.coverImage, floorplan.property.id)}
                         alt={floorplan.name}
                         onLoad={() => {
                             setIsImageLoaded(true);
                         }}/>
                </div>
            </div>
        </div>
    );
};

export interface SpotlightProps {
    floorplan: FloorplanSpotlight;
}