import React from "react";
import "./assets/PropertySkeleton.scss";
import Skeleton from "react-loading-skeleton";

export const PropertySkeleton: React.FC = () => {
    return (
        <section className="section-property--skeleton">
            <Skeleton/>
        </section>
    );
};