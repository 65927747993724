import React from 'react';
import './assets/AccessibilityHero.scss';
import heroImage from "./assets/Accesibility-hero.png";

export const AccessibilityHero: React.FC<AccessibilityHeroProps> = ({children}) => {

    return (
        <section className="section-hero-accessibility">
            <div className="hero-left">
                <img src={heroImage} className="img--hero" alt="hero"/>
            </div>
            <div className="hero-right">
                <div className="hero-content">
                    {children}
                </div>
            </div>
        </section>

    );
}

export interface AccessibilityHeroProps {
    children?: any;
}
