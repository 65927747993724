import React, {useState} from "react";
import "./assets/NeighborhoodSection.scss";
import {Button} from "@contentmunch/muncher-ui";
import bedIcon from "../spotlight/assets/bed-icon.png";
import bathIcon from "../spotlight/assets/bath-icon.png";
import {Neighborhood} from "./data/Neighborhood";
import {getAllNeighborhoods} from "./services/NeighborhoodService";

export const NeighborhoodSection: React.FC = () => {

    const neighborhoods: Neighborhood[] = getAllNeighborhoods();
    const [translate, setTranslate] = useState(0);

    const iconClickHandler = (index: number) => {
        setTranslate(-index * 100);
    };
    return (
        <section className="section-neighborhood">
            <h2 className="heading"><span className="emphasized">Our Neighborhoods</span></h2>
            <div className="icons">
                {
                    neighborhoods.map((neighborhood, i) =>
                        <div key={i} className={i % 2 === 0 ? "icon" : "icon icon-light"} onClick={() => {
                            iconClickHandler(i);
                        }}>
                            <img src={neighborhood.icon} alt="icon"/>
                        </div>
                    )}

            </div>
            <div className="neighborhood-slider">
                <div className="neighborhood-slider-container" style={{width: `${100 * neighborhoods.length}%`}}>
                    {neighborhoods.map((neighborhood, index) =>
                        <div className="neighborhood-slider-item"
                             style={{transform: `translateX(${translate}%)`}}
                             key={"neighborhood-slider-item" + index}>
                            <div className="neighborhood">
                                <iframe
                                    src={neighborhood.map}
                                    frameBorder="0"
                                    title="google map"
                                    aria-hidden="false"/>

                                <div className="right"
                                     style={{backgroundImage: `url(${neighborhood.hero})`}}>
                                    <div className="neighborhood-card">
                                        <header>
                                            <img src={neighborhood.logo}
                                                 alt={neighborhood.name}/>
                                        </header>
                                        <p><strong>{neighborhood.title}</strong></p>
                                        <p>{neighborhood.description}</p>
                                        <div className="footer">
                                            <Button variant="primary" onClick={() => {
                                                window.location.href = neighborhood.url;
                                            }}><a href={neighborhood.url}>Learn More</a></Button>
                                            <div className="metadata-items">
                                                <div className="metadata">
                                                    <img src={bedIcon} alt="bed"/>
                                                    <p className="value">{neighborhood.bedrooms}</p>
                                                </div>
                                                <div className="metadata">
                                                    <img src={bathIcon} alt="bath"/>
                                                    <p className="value">{neighborhood.bathrooms}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>

    );
};
