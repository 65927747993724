import React from 'react';
import './assets/BlogHighlightCard.scss';
import defaultAvatar from "@renaissancerentals/renaissance-component/lib/assets/default-avatar.png";
import {BlogProps} from "./data/Blog";

export const BlogHighlightCard: React.FC<BlogProps> = ({blog}) => {
    return (
        <div className="blog-highlight-card">
            <img src={blog.img == null ? defaultAvatar : blog.img} alt={blog.title}/>
            <p className="blog-highlight-card--title">{blog.title}</p>
            <p className="blog-highlight-card--content">{blog.snippet}</p>
            <p className="blog-highlight-card--footer">
                <a href={"/blogs/" + blog.id}>Continue reading »</a>
            </p>
        </div>
    );
}
