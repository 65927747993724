import React from "react";
import {Helmet} from "react-helmet-async";
import {SubletsSection} from "@renaissancerentals/renaissance-component";

export const SubletsPage: React.FC = () => {

    return (
        <>
            <Helmet>
                <title>Renaissance Rentals: Sublets</title>
            </Helmet>
            <SubletsSection linkToSubletCreatePage="/sublets/new"/>
        </>

    );
}