import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import {NotFound} from "./pages/NotFound";
import {Home} from "./pages/Home";
import "./assets/App.scss";
import HttpsRedirect from "react-https-redirect";
import {ContactModal} from "./components/contact/ContactModal";
import {ApplicationSection, Footer, InfoHeader, trackContactClicked} from "@renaissancerentals/renaissance-component";
import {Icon} from "@contentmunch/muncher-ui";
import {renaissance} from './data/RenaissanceData';
import {MainHeader} from "./components/header/mainHeader/MainHeader";
import {FooterNav} from "./components/footer/FooterNav";
import {BlogPage} from "./pages/BlogPage";
import {BlogsPage} from "./pages/BlogsPage";
import {AccessibilityPage} from "./pages/AccessibilityPage";
import {TeamPage} from "./pages/TeamPage";
import {MaintenanceSection} from "./components/maintenance/MaintenanceSection";
import {EmploymentPage} from "./pages/EmploymentPage";
import {EmploymentsPage} from "./pages/EmploymentsPage";
import {LeasingOfficesModal} from "./components/leasingOffice/LeasingOfficesModal";
import {LeasingOffice} from "./data/LeasingOffice";
import {getLeasingOffices} from "./services/LeasingOfficeService";
import {FloorplansPage} from "./pages/FloorplansPage";
import {UtilityConfirmationPage} from "./pages/UtilityConfirmationPage";
import {FloorplanPage} from "./pages/FloorplanPage";
import {PropertyPage} from "./pages/PropertyPage";
import {SubletsPage} from "./pages/SubletsPage";
import {SubletPage} from "./pages/SubletPage";


export const App: React.FC = () => {
    const [showContactModal, setShowContactModal] = useState(false);
    const [showLeasingOfficesModal, setShowLeasingOfficesModal] = useState(false);
    const [leasingOffices, setLeasingOffices] = useState<LeasingOffice[]>([]);
    const modalCloseHandler = () => {
        setShowContactModal(false);
        setShowLeasingOfficesModal(false);
    }
    const contactClickHandler = () => {
        setShowContactModal(true);
        trackContactClicked("renaissance-rentals");
    }
    const leasingOfficesClickHandler = () => {
        setShowLeasingOfficesModal(true);
    }

    useEffect(() => {
        getLeasingOffices().then(data => {
            setLeasingOffices(data);
        });

    }, []);

    return (
        <HttpsRedirect>
            <HelmetProvider>
                <Router>
                    <ContactModal showContactModal={showContactModal}
                                  contactModalCloseHandler={modalCloseHandler}/>
                    <LeasingOfficesModal showModal={showLeasingOfficesModal}
                                         modalCloseHandler={modalCloseHandler}
                                         leasingOffices={leasingOffices}
                    />
                    <InfoHeader>
                        <p>Call or Text <Icon name="phone"/>&nbsp;
                            <a href={"tel:" + renaissance.contact.number}>{renaissance.contact.display}</a></p>
                    </InfoHeader>
                    <MainHeader contactClickHandler={contactClickHandler}
                                leasingOfficesClickHandler={leasingOfficesClickHandler}/>
                    <main>
                        <Routes>
                            <Route path="/" element={<Home setShowContactModal={setShowContactModal}/>}/>
                            <Route path="/properties/:propertyId"
                                   element={<PropertyPage contactClickHandler={contactClickHandler}/>}/>
                            <Route path="/floorplans/:floorplanId"
                                   element={<FloorplanPage contactClickHandler={contactClickHandler}/>}/>
                            <Route path="/floorplans" element={<FloorplansPage/>}/>
                            <Route path="/accessibility"
                                   element={<AccessibilityPage contactClickHandler={contactClickHandler}/>}/>
                            <Route path="/blogs/:blogId" element={<BlogPage/>}/>
                            <Route path="/blog/:blogId" element={<BlogPage/>}/>
                            <Route path="/blogs" element={<BlogsPage/>}/>
                            <Route path="/contact"
                                   element={<Home showContactModal={true} setShowContactModal={setShowContactModal}/>}/>
                            <Route path="/maintenance" element={<MaintenanceSection/>}/>
                            <Route path="/employment/:employmentId" element={<EmploymentPage/>}/>
                            <Route path="/employment" element={<EmploymentsPage/>}/>
                            <Route path="/utility_confirmation" element={<UtilityConfirmationPage/>}/>
                            <Route path="/meet" element={<TeamPage contactClickHandler={contactClickHandler}/>}/>
                            <Route path="/apply"
                                   element={<ApplicationSection contactClickHandler={contactClickHandler}/>}/>
                            <Route path="/sublets/:uniqueId" element={<SubletPage/>}/>
                            <Route path="/sublets" element={<SubletsPage/>}/>
                            <Route path="*" element={<NotFound/>}/>
                        </Routes>
                    </main>
                    <Footer nav={<FooterNav/>}
                            accessibilityLink="/accessibility"/>
                </Router>
            </HelmetProvider>
        </HttpsRedirect>
    );
};
