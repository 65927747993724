import React from 'react';
import './assets/BlogContent.scss';
import HTMLReactParser from "html-react-parser";

export const BlogContent: React.FC<BlogContentProps> = ({content}) => {
    return (
        <div className="blog-content">
            {content ? HTMLReactParser(content) : ""}
        </div>
    );
}

export interface BlogContentProps {
    content?: string;
}