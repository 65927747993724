import React from 'react';
import "./assets/Accessibility.scss";
import {AccessibilityHero} from "../components/hero/AccessibilityHero";
import {FeatureSection} from "@renaissancerentals/renaissance-component";
import {Button} from "@contentmunch/muncher-ui";
import {Helmet} from "react-helmet-async";

export const AccessibilityPage: React.FC<AccessibilityPageProps> = ({contactClickHandler}) => {

    return (<>
            <Helmet>
                <title>Renaissance Rentals: Accessibility</title>
            </Helmet>

            <div className="accessibility-main">

                <AccessibilityHero>
                    <h1>A Home That Works For You</h1>
                    <p>We believe everyone deserves a home they feel comfortable in – we strive to help every
                        resident find a home they can enjoy, regardless of their abilities. </p>
                    <p>
                        If you have a disability that requires special accessibility amenities in your home,
                        please let us know. We have a variety of apartment homes that are built to be accessible
                        for people with disabilities. In addition, we can work with you to make special
                        accommodations and modifications to many of our other apartments so that they meet your
                        accessibility needs.
                    </p>
                    <Button onClick={contactClickHandler} variant="primary">Contact Us Today »</Button>
                </AccessibilityHero>
                <FeatureSection>
                    <h2><i>Have an accessibility request? Don’t hesitate to reach out!</i></h2>
                </FeatureSection>
                <div className="accessibility">
                    <div className="container">
                        <div className="accessibility__content">
                            <h2>We respect your privacy & we make the process easy</h2>
                            <p>We want you to be happy in your home, and if that means you need
                                accommodations
                                or modifications, we won’t make you jump through hoops to get what you need.</p>
                            <p>Simply let us know that you have a disability that requires an accommodation or
                                modification
                                in
                                your home. We have a short form for you (and in some cases, your health care provider)
                                to
                                complete,
                                in which you outline the accommodations or modifications you need. We will review the
                                form
                                with
                                you and work on a plan to make sure the home meets your accessibility needs.</p>
                            <hr/>
                            <p><i>Examples of accommodations & modifications we make for residents with accessibility
                                needs
                                include:</i></p>
                            <p>Concierge trash service, installation of grab bars near the bathtub, shower or toilet,
                                slide-bar
                                handheld showerheads, a ramp into the apartment, removal of cabinet fronts or interior
                                doors,
                                lever handles instead of knobs, and installation of accessible plumbing fixtures.</p>
                            <p> Please ask, we want to make you feel at home!</p>
                            <Button variant="secondary" onClick={contactClickHandler}>Contact Us »</Button>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export interface AccessibilityPageProps {
    contactClickHandler: () => void;
}