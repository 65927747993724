import {Neighborhood} from "../data/Neighborhood";

export const getAllNeighborhoods = (): Neighborhood[] => {
    return [
        covenanterHill, meadowCreek, highGrove, veronaPark, summerHouse, scholarsQuad, scholarsRooftop, scholarsRock, huntingtonGardens
    ];
};

export const covenanterHill: Neighborhood = {
    name: "Covenanter Hill",
    icon: "/icons/covenanter-hill.png",
    logo: "/logos/covenanter-hill.png",
    hero: "/hero/covenanter-hill.jpg",
    url: "/properties/covenanter-hill",
    bathrooms: "1 - 3.5",
    bedrooms: "1, 2 & 4",
    title: "Vibrant & unique 1, 2 & 4 bdrm apt homes",
    description: "Covenanter Hill is a relaxed walkable neighborhood located on Bloomington's lively east side with unique character, lush green spaces and vibrant colors.",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3093.8611781081063!2d-86.49508168464199!3d39.15513897953174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x886c6425bd99a755%3A0xad869a6f3d01abf8!2sCovenanter%20Hill%20Neighborhood%20District!5e0!3m2!1sen!2snp!4v1608462600688!5m2!1sen!2snp",

};
export const meadowCreek: Neighborhood = {
    name: "Meadow Creek Neighborhood",
    icon: "/icons/meadow-creek.png",
    logo: "/logos/meadow-creek.png",
    hero: "/hero/meadow-creek.jpg",
    url: "/properties/meadow-creek",
    bathrooms: "1 - 3",
    bedrooms: "1, 2 & 3",
    title: "Spacious apartment homes that are \"in town\", yet \"away from it all.\"",
    description: "Spacious and unique apartment homes featuring modern lodge-style architecture, walking paths, dog park, and pool! Come find your happy place at MeadowCreek",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3094.9123925794656!2d-86.50114368464273!3d39.13122497953528!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x886c65d86ff5a8ab%3A0x5eae7eed265f41f1!2sMeadowCreek+Neighborhood!5e0!3m2!1sen!2sus!4v1505058764673",

};
export const highGrove: Neighborhood = {
    name: "High Grove",
    icon: "/icons/high-grove.png",
    logo: "/logos/high-grove.png",
    hero: "/hero/high-grove.jpg",
    url: "/properties/high-grove",
    bathrooms: "1 - 2",
    bedrooms: "1 & 2",
    title: "Be connected and inspired next to nature",
    description: "HighGrove is a modern take on an English country villa located in a picturesque natural setting on Bloomington's desirable southeast side.",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d49504.98309052118!2d-86.55077873003576!3d39.15058411016426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x886c676a4d6e82b3%3A0x6ab41ccefdd6fa68!2sHighGrove!5e0!3m2!1sen!2sus!4v1526852219303",

};

export const veronaPark: Neighborhood = {
    name: "Verona Park",
    icon: "/icons/verona-park.png",
    logo: "/logos/verona-park.png",
    hero: "/hero/verona-park.jpg",
    url: "/properties/verona-park",
    bathrooms: "1 - 3",
    bedrooms: "1, 2 & 3",
    title: "Old-world authenticity meets modern amenities",
    description: "Upscale residences inspired by timeless European designs and surrounded by beautiful, natural settings, walking paths, and conveniently close to restaurants & shopping.",
    map: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12379.354642904153!2d-86.4983029!3d39.1329027!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe4902b98273c4639!2sVerona%20Park%20Neighborhood!5e0!3m2!1sen!2snp!4v1608463498905!5m2!1sen!2snp",

};

export const summerHouse: Neighborhood = {
    name: "Summer House",
    icon: "/icons/summer-house.png",
    logo: "/logos/summer-house.png",
    hero: "/hero/summer-house.jpg",
    url: "/properties/summer-house",
    bathrooms: "1 - 2.5",
    bedrooms: "1, 2 & 3",
    title: "Cottage-inspired apartments & townhomes available for long-term and short-term leases.",
    description: "Inviting apartments, townhomes, and lofts that are 1.8 miles to IU, next to a neighborhood park, and available furnished or unfurnished for as long or short as you need.",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3093.421265578703!2d-86.47658894862944!3d39.16514287942969!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x886c6469f78b443f%3A0xfd4391112a512760!2sSummerHouse%20at%20Indiana!5e0!3m2!1sen!2sus!4v1591135703275!5m2!1sen!2sus",

};

export const scholarsQuad: Neighborhood = {
    name: "Scholars Qaud",
    icon: "/icons/scholars-quad.png",
    logo: "/logos/scholars-quad.png",
    hero: "/hero/scholars-quad.jpg",
    url: "/properties/scholars-quad",
    bathrooms: "1 - 2.5",
    bedrooms: "1 & 2",
    title: "Conveniently located apartments that are close to campus & have easy access to public transit",
    description: "Spacious 1 - 2 Bedroom apartments with large, airy windows, thoughtfully designed floorplans, and are located less than a mile to the library.",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3093.163346204803!2d-86.50136868442064!3d39.1710071383743!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x886c641d8d0021a7%3A0x867f27af4d5ad886!2sScholar's+Quad+Collegiate+Apartments!5e0!3m2!1sen!2sus!4v1558991061602!5m2!1sen!2sus",

};
export const scholarsRooftop: Neighborhood = {
    name: "Scholars Rooftop",
    icon: "/icons/scholars-rooftop.png",
    logo: "/logos/scholars-rooftop.png",
    hero: "/hero/scholars-rooftop.jpg",
    url: "/properties/scholars-rooftop",
    bathrooms: "1",
    bedrooms: "1",
    title: "Modern-mediterranean 1 bedroom apartments with spacious, open layouts and a rooftop patio",
    description: "Warm and inviting apartments exquisitely finished with beautiful woodwork, stainless steel appliances, and modern Tuscan-style finishes.",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3092.8989023002355!2d-86.53549604847926!3d39.17701897942797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x886c66d0e8a67bd3%3A0x8b13a663d223bf63!2sScholar's+Rooftop!5e0!3m2!1sen!2sus!4v1563133402831!5m2!1sen!2sus",
}


export const scholarsRock: Neighborhood = {
    name: "Scholars Rock",
    icon: "/icons/scholars-rock.png",
    logo: "/logos/scholars-rock.png",
    hero: "/hero/scholars-rock.jpg",
    url: "/properties/scholars-rock",
    bathrooms: "1",
    bedrooms: "Studio & 1",
    title: "Conveniently located studio apartments just 3 blocks west of IU",
    description: "Live solo in peace and quiet in our easy-to-maintain studio apartments. Close to campus and peaceful; an ideal location for upperclassmen and graduate students.",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3092.847729916587!2d-86.53504229893531!3d39.17818223784854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfe051e9b7bea614a!2sScholar's%20Rock%20Studio%20Apartments!5e0!3m2!1sen!2snp!4v1608466099170!5m2!1sen!2snp",
}


export const huntingtonGardens: Neighborhood = {
    name: "Huntington Gardens",
    icon: "/icons/huntington-gardens.png",
    logo: "/logos/huntington-gardens.png",
    hero: "/hero/huntington-gardens.jpg",
    url: "/properties/huntington-gardens",
    bathrooms: "1",
    bedrooms: "1 & 2",
    title: "Thoughtfully designed 1 bedroom apartments in a quaint & quiet setting",
    description: "Enjoy our peaceful apartments on Bloomington's southeast side that have laminate hardwood, ample storage, and are within walking distance of Bryan PArk and the YMCA.",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3094.2487976020043!2d-86.52366628464227!3d39.14632247953304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x886c66f51bd1a957%3A0xfbca6a8911e15e81!2sS%20Huntington%20Gardens%20Pl%2C%20Bloomington%2C%20IN%2047401%2C%20USA!5e0!3m2!1sen!2snp!4v1608464404077!5m2!1sen!2snp",
}