import React from 'react';
import {Icon, Modal} from "@contentmunch/muncher-ui";
import "./assets/LeasingOfficesModal.scss";
import {LeasingOffice} from "../../data/LeasingOffice";
import {LeasingOfficeCard} from "../card/LeasingOfficeCard";

export const LeasingOfficesModal: React.FC<LeasingOfficesModalProps> = (
    {showModal, modalCloseHandler, leasingOffices}) => {

    return (
        <div className="div-leasing-offices--modal">

            <Modal show={showModal} setShow={modalCloseHandler}>
                <div className="close" onClick={modalCloseHandler}>
                    <Icon name="close" size="medium"/>
                </div>
                <section className="section-leasing-offices">
                    <div className="container">
                        <h3 className="heading"><span className="emphasized">Leasing office</span></h3>
                        <h5 className="heading"><span className="emphasized">Location &amp; Hours</span></h5>

                        <div className="div-leasing-offices">
                            {
                                leasingOffices.sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase())).map(leasingOffice =>
                                    <LeasingOfficeCard
                                        key={"leasingOfficeCard-" + leasingOffice.id}
                                        leasingOffice={leasingOffice}
                                    />
                                )}
                        </div>
                    </div>
                </section>
            </Modal>
        </div>
    )
};

export interface LeasingOfficesModalProps {
    showModal: boolean;
    modalCloseHandler: () => void;
    leasingOffices: LeasingOffice[];
}
