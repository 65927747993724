import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {useParams} from "react-router-dom";
import {defaultJobVacancy, employmentTypeToString, JobVacancy} from "../data/JobVacancy";
import {getJob} from "../services/EmploymentService";
import {NotFound} from "./NotFound";
import "./assets/EmploymentPage.scss";
import {capitalizeFirstLetter, enumToString, toUSD} from "@renaissancerentals/renaissance-component";
import HTMLReactParser from "html-react-parser";

export const EmploymentPage: React.FC = () => {
    const employmentParam = useParams();
    const [jobVacancy, setJobVacancy] = useState<JobVacancy>(defaultJobVacancy);
    const [isFound, setIsFound] = useState(true);
    useEffect(() => {
        if (employmentParam.employmentId) {
            getJob(employmentParam.employmentId)
                .then(data => setJobVacancy(data))
                .catch(() => {
                    setIsFound(false);
                });
        }

    }, [employmentParam.employmentId]);
    const structuredJSON = (): string => {

        let data = {
            "@context": "https://schema.org/",
            "@type": "JobPosting",
            "title": jobVacancy.title,
            "description": jobVacancy.description,
            "identifier": {
                "@type": "PropertyValue",
                "name": "RenaissanceRentals",
                "value": jobVacancy.id
            },
            "datePosted": jobVacancy.datePosted,
            "validThrough": jobVacancy.validThrough,
            "employmentType": jobVacancy.employmentType,
            "hiringOrganization": {
                "@type": "Organization",
                "name": "Renaissancerentals",
                "sameAs": "https://www.renaissancerentals.com",
                "logo": "https://www.renaissancerentals.com/logos/renaissance-rentals.png"
            },
            "jobLocation": {
                "@type": "Place",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "3321 S Cheekwood Ln.",
                    "addressLocality": "Bloomington",
                    "addressRegion": "IN",
                    "postalCode": "47401",
                    "addressCountry": "US"
                }
            },
            "baseSalary": {
                "@type": "MonetaryAmount",
                "currency": "USD",
                "value": {
                    "@type": "QuantitativeValue",
                    "value": jobVacancy.salary,
                    "unitText": jobVacancy.salaryType
                }
            }
        };
        return JSON.stringify(data);
    };
    return (isFound ?
            <div className="container">
                <Helmet>
                    <title>Now Hiring: {jobVacancy.title}</title>
                    <script type="application/ld+json">{structuredJSON()}</script>

                </Helmet>
                <section className="section-employment">
                    <h3>Now Hiring: {jobVacancy.title}</h3>

                    <div className="employment-metadata">
                        <p><strong>Start Date:</strong> {jobVacancy.startDate}</p>
                        <p><strong>Employment
                            Type:</strong> {capitalizeFirstLetter(employmentTypeToString(jobVacancy.employmentType))}, {jobVacancy.workHours}
                        </p>
                        <p><strong>Starting
                            Wages:</strong> {toUSD(jobVacancy.salary)}/{enumToString(jobVacancy.salaryType)} </p>
                    </div>
                    <h4>Job Description:</h4>

                    {jobVacancy.description ? HTMLReactParser(jobVacancy.description) : ""}

                </section>
            </div> : <NotFound/>
    );
};


export interface EmploymentParam {
    employmentId: string
}
