import React from "react";
import "./assets/AboutSection.scss";
import {Button} from "@contentmunch/muncher-ui";

export const AboutSection: React.FC<AboutSectionProps> = ({contactClickHandler}) => {
    return (
        <section className="section-about">
            <h2 className="heading"><span className="emphasized">About Renaissance</span></h2>

            <div className="div-about">
                <div className="left"/>
                <div className="right">
                    <div className="right-content">
                        <header>Experience is Everything</header>
                        <p>At Renaissance Rentals, we know the value of community because Bloomington is our home, too.
                            As a
                            locally owned and operated business for over 30 years, we’ve built our reputation on doing
                            the
                            right thing and always providing the best service possible.
                        </p>
                        <p>Our team is here to help you find your next home in our community. Welcome to Bloomington, we
                            know you’ll love it here.</p>
                        <Button variant="secondary" onClick={contactClickHandler}>Contact us today</Button>
                    </div>
                </div>
            </div>

        </section>
    );
};

export interface AboutSectionProps {
    contactClickHandler: () => void;
}