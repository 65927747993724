import React from "react";
import Skeleton from "react-loading-skeleton";
import "./assets/Spotlight.scss";
import {SpotlightTextSkeleton} from "./SpotlightTextSkeleton";

export const SpotlightSkeleton: React.FC = () => {
    return (
        <div className="spotlight">
            <div className="left">
                <SpotlightTextSkeleton/>
            </div>
            <div className="right">
                <Skeleton height={250}/>
            </div>
        </div>
    );
};
