import React from "react";
import "./assets/HomeCard.scss";

export const HomeCard: React.FC<HomeCardProps> = ({children}) => {
    return (
        <div className="home-card">
            {children}
        </div>
    );
};

export interface HomeCardProps {
    children?: any;
}
