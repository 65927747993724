import {LeasingOffice} from "../data/LeasingOffice";
import {LeasingOfficeType, PropertyLeaseDetails} from "../data/Property";
import {get} from "@renaissancerentals/renaissance-component";

export const getLeasingOffices = async (): Promise<LeasingOffice[]> => {
    let response = await get("leasingOffices?projection=details");
    return await response.data._embedded.leasingOffices;
};
export const filterProperties = (properties: PropertyLeaseDetails[]): PropertyLeaseDetails[] => {

    return properties.filter(
        value => value.active
            && !value.name.includes("ffordable")
            && !value.name.includes("arage")
    );
};
export const officeTypeToString = (leasingOfficeType: LeasingOfficeType) => {
    switch (leasingOfficeType) {
        case LeasingOfficeType.OFF_SITE:
            return "OFF-SITE"
        default:
            return "ON-SITE";
    }
}
