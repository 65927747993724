import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {
    convertToFloorplanCardData,
    FloorplanSectionSkeleton,
    FloorplansSection,
    getProperty,
    HomePageSpecialSection,
    PropertyDetails,
    PropertyId,
    PropertyLocation,
    sortFloorplans,
    TeamSection,
} from "@renaissancerentals/renaissance-component";
import {Li, Ul} from "@contentmunch/muncher-ui";
import {NotFound} from "./NotFound";
import "./assets/PropertyPage.scss";
import {PropertySkeleton} from "../components/property/PropertySkeleton";
import {PropertyAction} from "../components/property/PropertyAction";
import {PropertyHero} from "../components/property/PropertyHero";
import {ReviewSection} from "@renaissancerentals/renaissance-component/lib/review/ReviewSection";
import {SummerHouseShortTerm} from "../components/property/SummerHouseShortTerm";
import {Helmet} from "react-helmet-async";

export const PropertyPage: React.FC<PropertyPageProps> = (
    {contactClickHandler}) => {
    const propertyParam = useParams();
    const [property, setProperty] = useState<PropertyDetails>({} as PropertyDetails);
    const [isLoading, setIsLoading] = useState(true);
    const [isFound, setIsFound] = useState(true);

    const amenitiesRef = useRef<HTMLDivElement>(null);
    const floorPlanRef = useRef<HTMLDivElement>(null);
    const mapRef = useRef<HTMLDivElement>(null);
    const handleRefToFloorPlan = () => {
        floorPlanRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };
    const handleRefToMap = () => {
        mapRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };
    const handleRefToAmenities = () => {
        amenitiesRef.current?.scrollIntoView({
            behavior: "smooth",
            block: "start"
        });
    };
    useEffect(() => {

        getProperty(propertyParam.propertyId as PropertyId).then((data) => {
            setProperty(data);
        }).catch(() => {
            setIsFound(false);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [propertyParam.propertyId]);
    return (
        isFound ?
            !isLoading ?
                <>
                    <Helmet>
                        <title>{property.htmlTitle}</title>
                        <meta name="description"
                              content={property.metaDescription}/>
                    </Helmet>
                    {
                        propertyParam.propertyId === "summer-house-short-term" ?
                            <SummerHouseShortTerm isLoading={isLoading} property={property}
                                                  handleRefToContact={contactClickHandler}/> :
                            <section className="section-property">
                                <HomePageSpecialSection propertyId={property.id}/>
                                <PropertyHero property={property}/>
                                <nav className="property-nav">
                                    <div className="container">
                                        <h5><a href="#amenities" onClick={handleRefToAmenities}>amenities</a></h5>
                                        <h5><a href="#floorplans" onClick={handleRefToFloorPlan}>floorplans</a></h5>
                                        <h5><a href="#location" onClick={handleRefToMap}>location</a></h5>
                                    </div>
                                </nav>


                                <PropertyAction handleRefToFloorPlan={handleRefToFloorPlan}
                                                handleRefToContact={contactClickHandler}
                                                property={property}
                                                isLoading={isLoading}/>

                                <div ref={amenitiesRef} id="amenities" className="reference">
                                </div>
                                <div className="container amenities-section">
                                    <h2>Amenities</h2>

                                    <Ul>
                                        {property.amenities.map(amenity =>
                                            <Li key={"amenity-" + amenity.id}
                                                isFeatured={amenity.featured}>{amenity.name}</Li>
                                        )}
                                    </Ul>


                                </div>


                                <div ref={floorPlanRef} id="floorplans" className="reference">
                                </div>
                                {isLoading ?
                                    <FloorplanSectionSkeleton/> :
                                    propertyParam.propertyId ?
                                        <>{property?.floorplans ? <FloorplansSection
                                            propertyId={propertyParam.propertyId}
                                            floorplans={
                                                sortFloorplans(
                                                    property.floorplans.filter(floorplan => floorplan.active).map(floorplan => convertToFloorplanCardData(floorplan)
                                                    ), "featured")
                                            }/> : ""}

                                        </> : <NotFound/>

                                }
                                <ReviewSection property={property}/>
                                <div ref={mapRef} id="location" className="reference">

                                </div>
                                <div className="container location-section">
                                    <h2>Location</h2>
                                </div>
                                <PropertyLocation property={property} isLoading={isLoading}
                                                  handleRefToContact={contactClickHandler}/>

                                {propertyParam.propertyId ?
                                    <TeamSection isLoading={isLoading} teamMembers={property.teamMembers}
                                                 propertyId={propertyParam.propertyId}/> : ""}


                            </section>
                    }
                </> : <PropertySkeleton/> :

            <NotFound/>
    );
}

export interface PropertyPageProps {
    contactClickHandler: () => void;
}

export interface PropertyParam {
    propertyId: PropertyId | "summer-house-short-term"
}
