import React, {useCallback} from "react";
import {FloorplanSection} from "@renaissancerentals/renaissance-component";
import "./assets/FloorplansPage.scss";
import {useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import {NotFound} from "./NotFound";

export const FloorplanPage: React.FC<FloorplanPageProps> = (
    {contactClickHandler}) => {
    const floorplanParam = useParams();
    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/apply'), [navigate]);

    return (
        <>
            <Helmet>
                <title>Renaissance Rentals: Floorplan: {floorplanParam.floorplanId}</title>
            </Helmet>
            {floorplanParam.floorplanId ? <FloorplanSection contactClickHandler={contactClickHandler}
                                                            applyClickHandler={handleOnClick}
                                                            floorplanId={floorplanParam.floorplanId}/>
                : <NotFound/>
            }


        </>
    );
};

export interface FloorplanPageProps {
    contactClickHandler: () => void;
}
