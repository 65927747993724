import React from 'react';
import {Link} from "react-router-dom";
import {Button, DropdownNavButton} from "@contentmunch/muncher-ui";

export const Nav: React.FC<NavProps> = (
    {modalClose, contactClickHandler, leasingOfficesClickHandler, children}) => {

    return (
        <nav>
            {children}
            <Button size="medium" title="search apartments" type="nav">
                <h5><Link to="/floorplans" onClick={() => {
                    modalClose()
                }}>search apartments</Link></h5>
                {/*<h5>*/}
                {/*    <a href="https://apartments.renaissancerentals.com/search">search apartments</a>*/}
                {/*</h5>*/}
            </Button>

            <DropdownNavButton element={<h5>communities&nbsp;<span className="small">&#9660;</span></h5>}>

                <h5><a href="/properties/covenanter-hill">covenanter hill</a></h5>
                <h5><a href="/properties/high-grove">highgrove</a></h5>
                <h5><a href="/properties/huntington-gardens">huntington gardens</a></h5>
                <h5><a href="/properties/meadow-creek">Townhomes at MeadowCreek</a></h5>
                <h5><a href="/properties/scholars-quad">scholar's quad</a></h5>
                <h5><a href="/properties/scholars-rock">scholar's rock</a></h5>
                <h5><a href="/properties/scholars-rooftop">scholar's rooftop</a></h5>
                <h5><a href="/properties/summer-house">summerhouse at indiana</a></h5>
                <h5><a href="/properties/summer-house-short-term">summerhouse short term</a></h5>
                <h5><a href="/properties/verona-park">Verona Park Neighborhood</a></h5>
            </DropdownNavButton>
            <DropdownNavButton element={<h5>for residents&nbsp;<span className="small">&#9660;</span></h5>}>
                <h5><a href="https://renaissancerentals.appfolio.com/connect/users/sign_in">make a payment</a></h5>
                <h5><a href="https://app.mezo.io/r/renaissance">submit maintenance request</a></h5>
                <h5><Link to="/utility_confirmation" onClick={() => {
                    modalClose()
                }}>utility confirmation form</Link></h5>
                <h5><Link to="/sublets" onClick={() => {
                    modalClose()
                }}>sublets</Link></h5>

            </DropdownNavButton>

            <DropdownNavButton element={<h5>about&nbsp;<span className="small">&#9660;</span></h5>} active={true}>
                <h5><Link to="/meet" onClick={() => {
                    modalClose()
                }}>meet us</Link></h5>
                <h5><Link to="/blogs" onClick={() => {
                    modalClose()
                }}>read our blog</Link></h5>
                <h5><Link to="/employment" onClick={() => {
                    modalClose()
                }}>employment opportunities</Link></h5>
                <h5><Link to="/accessibility" onClick={() => {
                    modalClose()
                }}>Accessibility Info</Link></h5>

                <Button size="small" title="Leasing Offices" type="nav"
                        onClick={() => {
                            modalClose();
                            leasingOfficesClickHandler();
                        }}><h5>Leasing Offices</h5></Button>
            </DropdownNavButton>
            <Button size="medium" title="apply" type="nav">
                <h5><Link to="/apply" onClick={() => {
                    modalClose()
                }}>apply</Link></h5>
            </Button>
            <Button size="medium" title="contact" type="nav"
                    onClick={() => {
                        modalClose();
                        contactClickHandler();
                    }}><h5>contact</h5></Button>
        </nav>
    )
};

export interface NavProps {
    modalClose: () => void;
    contactClickHandler: () => void;
    leasingOfficesClickHandler: () => void;
    children?: any;
}
