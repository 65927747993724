import {JobVacancy} from "../data/JobVacancy";
import {get} from "@renaissancerentals/renaissance-component";

export const getJob = async (jobId: string): Promise<JobVacancy> => {
    let response = await get("jobVacancies/" + jobId + "?projection=withId");
    return await response.data;
};
export const getJobs = async (): Promise<JobVacancy[]> => {
    const response = await get("jobVacancies/search/findAllByActiveTrue?projection=withId");
    return response.data._embedded.jobVacancies;
};

