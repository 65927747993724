import {decodeEntity} from "html-entities";
import moment from "moment";
import {DateInput} from "../data/Calendar";
import {isEmpty} from "@renaissancerentals/renaissance-component";
import {Pet} from "@renaissancerentals/renaissance-component/lib/floorplan/data/Unit";
import {AVAILABLE_NOW} from "@renaissancerentals/renaissance-component/lib/floorplan/service/FloorplanService";

export const decode = (content: string) => {
    return decodeEntity(content);
};
export const isGoogleDriveImage = (imageUrl: string) => imageUrl.includes("drive.google.com");

export const extractIdFrom = (imageUrl: string): string => {

    const paramString = imageUrl.split('?')[1];
    const queryString = new URLSearchParams(paramString);
    const id = queryString.get("id");
    return id === null ? "" : id;

};


export const dateInputArray = () => {
    const months: DateInput[] = [];
    months.push({
        index: AVAILABLE_NOW,
        name: AVAILABLE_NOW
    });
    for (let i = 0; i < 12; i++)
        months.push({
            index: moment().add(i, "month").format('MM-YYYY'),
            name: moment().add(i, "month").format('MMMM')
        });

    return months;
}
export const petToString = (pet: Pet): string => {
    switch (pet) {
        case Pet.CAT:
            return "Cat";
        case Pet.SMALL_DOG_CAT:
            return "Small Dog or Cat";
        case Pet.LARGE_DOG_SMALL_DOG_CAT:
            return "Large Dog, Small Dog or Cat";
        default:
            return "No Pets";
    }
};

export const petStringToPet = (pet: string): Pet => {
    switch (pet) {
        case "Cat":
            return Pet.CAT;
        case "Small Dog or Cat":
            return Pet.SMALL_DOG_CAT;
        case "Large Dog, Small Dog or Cat":
            return Pet.LARGE_DOG_SMALL_DOG_CAT;
        default:
            return Pet.NO_PET;
    }
}
export const isZipcodeValid = (zipcode?: string): boolean => {
    if (!zipcode)
        return true;
    const zipcodePattern = /^\d{5}$/;
    return zipcodePattern.test(zipcode);
};
export const enumToString = (value?: string): string => {

    return value && !isEmpty(value) ? value.replaceAll("_", " ").toLowerCase() : "";
};

export const formatDate = (date: string): string => {
    return moment(date, "YYYY-MM-DD").format('MMM DD, YYYY');
};