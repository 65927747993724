import React, {useEffect, useState} from 'react';
import {
    DriveGallery,
    FeatureSection,
    getAllTeamMembers,
    PropertyTeamMember,
    TeamSection
} from "@renaissancerentals/renaissance-component";
import {Button} from "@contentmunch/muncher-ui";
import {useNavigate} from "react-router-dom";
import "./assets/TeamPage.scss";
import {TeamHero} from "../components/hero/TeamHero";
import {BlogHighlightSection} from "../components/blog/sections/BlogHighlightSection";
import {Helmet} from "react-helmet-async";
import {propertyId} from "../services/Api";

export const TeamPage: React.FC<TeamPageProps> = ({contactClickHandler}) => {

    let navigate = useNavigate();
    const [teamMembers, setTeamMembers] = useState<PropertyTeamMember[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const jobOpportunitiesHandler = () => {
        navigate("/employment");
    };
    useEffect(() => {
        getAllTeamMembers().then(data => {
            setTeamMembers(data);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);
    return (
        <div className="team-main">
            <Helmet>
                <title>Renaissance Rentals: Teams</title>
            </Helmet>
            <TeamHero/>
            <FeatureSection>
                <div className="feature-content container">
                    <h2><i>Join the Renaissance Rentals Team, we’d love to have you!</i></h2>
                    <Button onClick={jobOpportunitiesHandler}>VIEW JOB OPPORTUNITIES »</Button>
                </div>
            </FeatureSection>
            <TeamSection isLoading={isLoading} teamMembers={teamMembers} propertyId={propertyId}/>
            <div className="div-align--center">
                <Button variant="secondary" onClick={contactClickHandler}>Contact Us »</Button>
            </div>
            <div className="container">
                <h2>Life at Renaissance Rentals</h2>
                <DriveGallery driveId="0B6C97UNWKNaIMXNZWFBBNmV2enM" type="grid" propertyId={propertyId}/>
            </div>
            <BlogHighlightSection/>
        </div>
    );
}

export interface TeamPageProps {
    contactClickHandler: () => void;
}
