export interface JobVacancy {
    id: string;
    title: string;
    description: string;
    validThrough: string;
    employmentType: EmploymentType;
    salary: number;
    salaryType: SalaryType;
    startDate: string;
    workHours: string;
    lastModifiedBy: string;
    datePosted: string;
    lastModifiedDate: string;
    active: boolean;
}

export enum EmploymentType {
    FULL_TIME = "FULL_TIME", PART_TIME = "PART_TIME", CONTRACTOR = "CONTRACTOR",
    TEMPORARY = "TEMPORARY", INTERN = "INTERN", OTHER = "OTHER"

}

export enum SalaryType {
    HOUR = "HOUR", WEEK = "WEEK", MONTH = "MONTH", YEAR = "YEAR"
}

export const defaultJobVacancy: JobVacancy = {
    id: "",
    title: "",
    description: "",
    validThrough: "",
    employmentType: EmploymentType.FULL_TIME,
    salary: 0,
    salaryType: SalaryType.HOUR,
    startDate: "",
    workHours: "",
    datePosted: "",
    lastModifiedDate: "",
    lastModifiedBy: "",
    active: true
}

export const employmentTypeToString = (type: EmploymentType) => {
    switch (type) {
        case EmploymentType.FULL_TIME:
            return "Full-time";
        case EmploymentType.PART_TIME:
            return "Part-time";
        default:
            return type;

    }
};