import React from "react";
import Skeleton from "react-loading-skeleton";
import "./assets/Spotlight.scss";

export const SpotlightTextSkeleton: React.FC = () => {
    return (
        <div className="unit-spotlight">
            <header><Skeleton/></header>
            <p><Skeleton/></p>
            <div className="unit-metadata">
                <div className="metadata">
                    <Skeleton/>
                    <p className="value"><Skeleton/></p>
                </div>
                <div className="metadata">
                    <Skeleton/>
                    <p className="value"><Skeleton/></p>
                </div>
                <div className="metadata">
                    <Skeleton/>
                    <p className="value"><Skeleton/></p>
                </div>
                <div className="metadata">
                    <Skeleton/>
                    <p className="value"><Skeleton/></p>
                </div>
                <div className="metadata">
                    <Skeleton/>
                    <p className="value"><Skeleton/></p>
                </div>
                <div className="metadata">
                    <Skeleton/>
                </div>
            </div>
        </div>

    );
};
