import React, {useEffect, useState} from "react";
import "./assets/SpotlightSection.scss";
import {Button, Icon} from "@contentmunch/muncher-ui";
import {SpotlightSkeleton} from "./SpotlightSkeleton";
import {Spotlight} from "./Spotlight";
import {getFeaturedFloorplans} from "../floorplan/services/FloorplanService";
import {FloorplanSpotlight} from "../floorplan/data/Floorplan";

export const SpotlightSection: React.FC = () => {
    const [floorplans, setFloorplans] = useState<FloorplanSpotlight[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [translate, setTranslate] = useState(0);
    useEffect(() => {

        getFeaturedFloorplans().then(value => {
            setFloorplans(value);

        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    const increaseIndex = () => {

        if (currentIndex === floorplans.length - 1) {
            setCurrentIndex(0);
            setTranslate(0);

        } else {
            setTranslate(translate - 100);
            setCurrentIndex(currentIndex + 1);
        }

    };
    const decreaseIndex = () => {
        if (currentIndex === 0) {
            setTranslate(-(floorplans.length - 1) * 100)
            setCurrentIndex(floorplans.length - 1);
        } else {

            setTranslate(translate + 100);
            setCurrentIndex(currentIndex - 1);
        }
    };
    return (
        <div className="container">
            <section className="section-spotlight">
                <h2 className="heading"><span className="emphasized">Apartment Spotlight</span></h2>
                <div className="spotlights">
                    <div className="spotlight-left mobile">
                        <Button variant="transparent" onClick={decreaseIndex}>
                            <Icon name="chevron-left" size="large" weight={2}/>
                        </Button>
                    </div>
                    <div className="spotlight-left main">
                        <Button variant="transparent" onClick={decreaseIndex}>
                            <Icon name="arrow-left" size="medium" weight={2}/>
                        </Button>
                    </div>
                    {isLoading ? <SpotlightSkeleton/> :
                        <div className="slider">
                            <div className="slider-container" style={{width: `${100 * floorplans.length}%`}}>
                                {floorplans.map((floorplan, index) =>
                                    <div className="slider-item"
                                         style={{transform: `translateX(${translate}%)`}}
                                         key={"slider-item" + index}>
                                        <Spotlight floorplan={floorplan}/>

                                    </div>
                                )}
                            </div>
                        </div>
                    }

                    <div className="spotlight-right mobile">
                        <Button variant="transparent" onClick={increaseIndex}>
                            <Icon name="chevron-right" size="large" weight={2}/>
                        </Button>
                    </div>
                    <div className="spotlight-right main">
                        <Button variant="transparent" onClick={increaseIndex}>
                            <Icon name="arrow-right" size="medium" weight={2}/>
                        </Button>
                    </div>
                </div>
            </section>
        </div>
    );
};