import React from "react";
import {Helmet} from "react-helmet-async";
import {useParams} from "react-router-dom";
import {NEW_SUBLET, SubletSection} from "@renaissancerentals/renaissance-component";

export const SubletPage: React.FC = () => {
    const subletParam = useParams();
    return (
        <>
            <Helmet>
                <title>Renaissance Rentals: Sublets:{subletParam.uniqueId}</title>
            </Helmet>
            <SubletSection uniqueId={NEW_SUBLET}/>
        </>

    );
}
